import { FilterCheckboxItem, SpecificFacetType } from '../../../../../shared/models';

export class LanguageFilterData {
  private static list: string[] = [
    'english',
    'russian',
    'spanish',
    'french',
    'german',
    'ukrainian',
    'hindi',
    'polish',
    'chinese',
    'italian',
    'portuguese',
    'dutch',
    'hebrew',
    'japanese',
    'arabic',
    'swedish',
    'thai',
    'romanian',
    'turkish',
    'tamil',
    'czech',
    'greek',
    'mandarin',
    'korean',
    'urdu',
    'danish',
    'hungarian',
    'indonesian',
    'bulgarian',
    'finnish',
    'latin',
    'persian',
    'slovak',
    'vietnamese',
    'serbian',
    'croatian',
    'latvian',
    'catalan',
    'lithuanian',
    'norwegian',
    'tagalog',
    'armenian',
    'afrikaans',
    'estonian',
    'filipino',
    'malay',
    'slovenian',
    'kazakh',
    'azerbaijani',
    'nepali',
    'farsi',
    'georgian',
    'sanskrit',
    'uzbek',
    'swahili',
    'irish',
    'javanese',
    'macedonian',
    'albanian',
    'bosnian'
  ];

  private static langLevelList = [
    'l1_elementary',
    'l2_pre_intermediate',
    'l3_intermediate',
    'l4_advanced',
    'l5_fluent_or_native'
  ];

  static getFilterList(isExtended?: boolean): FilterCheckboxItem[] {
    return LanguageFilterData.list.map((language: string, index: number) => {
      const label = LanguageFilterData.getLanguageLabel(language);
      const cssClass = LanguageFilterData.getLanguageCssClass(language);
      const facetFilterType: SpecificFacetType =
        LanguageFilterData.getLanguageFacetFilterType(language);

      const queries = isExtended ? this.getLangLevelList(language, this.langLevelList) : null;

      return {
        value: language,
        label,
        class: cssClass,
        id: index,
        facetType: 'languages',
        specificFacetType: facetFilterType,
        checked: false,
        queries
      };
    });
  }

  private static getLanguageLabel(language: string): string {
    const translateLangKey = 'LANGUAGES';
    const separator = '.';

    return `${translateLangKey}${separator}${language.toUpperCase()}`;
  }

  private static getLanguageCssClass(language: string): string {
    const cssClassPrefix = 'language';
    const separator = '-';

    return `${cssClassPrefix}${separator}${language}`;
  }

  private static getLanguageFacetFilterType(language: string): SpecificFacetType {
    const facetFilterTypePrefix = 'lang';
    const separator = '_';

    return `${facetFilterTypePrefix}${separator}${language}` as SpecificFacetType;
  }

  private static getLangLevelList(language: string, levelList: string[]): any {
    return levelList.reduce((acc, level) => {
      const levelObject: FilterCheckboxItem = {
        label: `LANG_LEVELS.${level.slice(3).toUpperCase()}`,
        value: `${language}_${level}`,
        checked: false
      };
      acc.push(levelObject);
      return acc;
    }, []);
  }
}
