import { Candidate } from '../candidate';
import { NameForLang } from '../localization';
import { SortOrderType } from '../../types';

export enum SearchState {
  EMPTY = 'empty',
  NEW = 'new',
  SENT = 'sent',
  NEW_DISABLED = 'new_disabled'
}

export interface SearchRequestInfo {
  pageNumber?: number;
  sort?: SearchSortType;
  order?: SortOrderType;
  q?: string[] | string;
  f?: string[];
  facet?: string[];
  pageSize?: number;
  defaultSearch?: boolean;
}

export interface SearchResponse extends PaginationInfo {
  frontProfileDtos: Candidate[];
  withUpdating?: boolean;
}

export interface PaginationInfo {
  numFound?: number;
  page?: number;
  pageSize?: number;
}

export interface SearchQueryInfo {
  queryForSearchObjects: string;
  queryWithFacets?: string;
}

export interface SearchSort extends NameForLang {
  sort: SearchSortType;
  name?: string;
  active?: boolean;
  order?: SortOrderType;
}

export type SearchSortType =
  | 'total_experience'
  | 'last_place_experience'
  | 'gross_rating'
  | 'profile_edit_date'
  | 'readiness_change'
  | 'recently_changed'
  | 'havent_changed'
  | 'wn_score';

export type SearchSidebarMenuItem = 'newSearch' | 'savedSearches' | 'searchHistory';

export type FacetType =
  | 'contacts'
  | 'languages'
  | 'levels'
  | 'companySize'
  | 'industry'
  | 'currentIndustry'
  | 'openToWork'
  | 'lang-levels';

export type SpecificFacetType =
  | 'contacts_withOrWithout'
  | 'contacts_withAny'
  | 'contacts_withoutAny'
  | 'contacts_withPhone'
  | 'contacts_withEmail'
  | 'lang_english'
  | 'lang_russian'
  | 'lang_spanish'
  | 'lang_french'
  | 'lang_german'
  | 'lang_ukrainian'
  | 'lang_hindi'
  | 'lang_polish'
  | 'lang_chinese'
  | 'lang_italian'
  | 'lang_portuguese'
  | 'lang_dutch'
  | 'lang_hebrew'
  | 'lang_japanese'
  | 'lang_arabic'
  | 'lang_swedish'
  | 'lang_thai'
  | 'lang_romanian'
  | 'lang_turkish'
  | 'lang_tamil'
  | 'lang_czech'
  | 'lang_greek'
  | 'lang_mandarin'
  | 'lang_korean'
  | 'lang_urdu'
  | 'lang_danish'
  | 'lang_hungarian'
  | 'lang_indonesian'
  | 'lang_bulgarian'
  | 'lang_finnish'
  | 'lang_latin'
  | 'lang_persian'
  | 'lang_slovak'
  | 'lang_vietnamese'
  | 'lang_serbian'
  | 'lang_croatian'
  | 'lang_latvian'
  | 'lang_catalan'
  | 'lang_lithuanian'
  | 'lang_norwegian'
  | 'lang_tagalog'
  | 'lang_armenian'
  | 'lang_afrikaans'
  | 'lang_estonian'
  | 'lang_filipino'
  | 'lang_malay'
  | 'lang_slovenian'
  | 'lang_kazakh'
  | 'lang_azerbaijani'
  | 'lang_nepali'
  | 'lang_farsi'
  | 'lang_georgian'
  | 'lang_sanskrit'
  | 'lang_uzbek'
  | 'lang_swahili'
  | 'lang_irish'
  | 'lang_javanese'
  | 'lang_macedonian'
  | 'lang_albanian'
  | 'lang_bosnian'
  | 'level_junior'
  | 'level_middle'
  | 'level_senior'
  | 'level_teamlead'
  | 'level_architect'
  | 'level_cto'
  | 'level_pm'
  | 'size_0'
  | 'size_2'
  | 'size_11'
  | 'size_51'
  | 'size_201'
  | 'size_501'
  | 'size_1001'
  | 'size_5001'
  | 'size_10001'
  | 'open_to_work_li'
  | 'l1_elementary'
  | 'l2_pre_intermediate'
  | 'l3_intermediate'
  | 'l4_advanced'
  | 'l5_fluent_or_native';

export type FacetResponseData = {
  [filterType in SpecificFacetType]: number;
} & {
  total?: number;
};
